import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../redux/store';
import { IResponsePayload, IVerityFPNResponse, IVerityRPNResponse } from '../../interfaces/interfaces';
import { verityApi } from '../../services/api';
import { USER_ROLES } from '../../constants/constants';

export type OverviewState = {
    overview: Record<string, IResponsePayload>
    contactInfo: Record<string, IResponsePayload>
    documentLinks: Record<string, IResponsePayload>
}

export type VerityDataState = {
    programName: string
    aliases: string[]
    programStatus: string
    isBRDApproved: boolean | null
    isNameApproved: boolean | null
    isOnRoadmap: boolean | null
    isPRFAQApproved: boolean | null
    prfaqLink: string | null
    asinCount: number
    ppls_id: string
    productCode: string
    createdDate: string
}

type ProgramOverviewState = {
    serverData: OverviewState
    clientData: OverviewState
    verityData: VerityDataState
    userRole: string
};

const initialState: ProgramOverviewState = {
    serverData: {
        overview: {},
        contactInfo: {},
        documentLinks: {}
    },
    clientData: {
        overview: {},
        contactInfo: {},
        documentLinks: {}
    },
    verityData: {
        programName: '',
        aliases: [],
        programStatus: '',
        isBRDApproved: null,
        isNameApproved: null,
        isOnRoadmap:  null,
        isPRFAQApproved: null,
        prfaqLink: null,
        asinCount: -1,
        ppls_id: '',
        productCode: '',
        createdDate: ''
    },
    userRole: USER_ROLES.readonly
};

export const programOverviewSlice = createSlice({
    name: 'programOverview',
    initialState,
    reducers: {
        resetOverview: (state) => {
            state.serverData = initialState.serverData;
            state.clientData = initialState.clientData;
        },
        resetVerityData: (state) => {
            state.verityData = initialState.verityData;
        },
        discardOverviewUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateOverviewServerData: (state, action) => {
            state.serverData = action.payload;
        },
        updateOverviewClientData: (state, action) => {
            state.clientData = action.payload;
        },
        updateOverview: (state, action) => {
            state.clientData.overview = action.payload;
        },
        updateContactInfo: (state, action) => {
            state.clientData.contactInfo = action.payload;
        },
        updateDocumentLinks: (state, action) => {
            state.clientData.documentLinks = action.payload;
        },
        updateUserRole: (state, action) => {
            state.userRole = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                verityApi.endpoints.getFPNDetails.matchFulfilled,
                (state, { payload }) => {
                    if (payload?.data?.get_fpn?.body?.length){
                        const fpnData : IVerityFPNResponse = payload.data?.get_fpn?.body[0];
                        state.verityData.asinCount = fpnData.asin_count;
                    } 
                }
            )
            .addMatcher(
                verityApi.endpoints.getRPNDetails.matchFulfilled,
                (state, { payload }) => {
                    if (payload?.data?.get_rpn?.body?.length) {
                        const rpnData : IVerityRPNResponse = payload.data?.get_rpn?.body[0];
                        state.verityData = {
                            programName: rpnData.rpn,
                            aliases: rpnData.rpn_alias ?? [],
                            programStatus: rpnData.program_status,
                            isBRDApproved: rpnData.is_brd_approved,
                            isNameApproved: rpnData.is_name_approved,
                            isOnRoadmap: rpnData.is_on_roadmap,
                            isPRFAQApproved: rpnData.is_prfaq_approved,
                            prfaqLink: rpnData.prfaq_link,
                            asinCount: rpnData.asin_count,
                            ppls_id: rpnData.ppls_id,
                            productCode: rpnData.product_code,
                            createdDate: rpnData.created_ts?.split(' ')[0] ?? ''
                        };
                    }
                }
            );
    }
});

export const { resetOverview, resetVerityData, discardOverviewUpdates, updateOverviewServerData, 
    updateOverviewClientData, updateOverview, updateContactInfo, updateDocumentLinks, updateUserRole } = programOverviewSlice.actions;
 
export default programOverviewSlice.reducer;

export const selectServerData = (state: RootState) => state.programOverview.serverData;
export const selectClientData = (state: RootState) => state.programOverview.clientData;
export const selectVerityData = (state: RootState) => state.programOverview.verityData;
export const selectOverview = (state: RootState) => state.programOverview.clientData.overview;
export const selectContactInfo = (state: RootState) => state.programOverview.clientData.contactInfo;
export const selectDocumentLinks = (state: RootState) => state.programOverview.clientData.documentLinks;
export const selectOverviewModified = (state: RootState) => !_.isEqual(state.programOverview.clientData, state.programOverview.serverData);
export const selectUserRole = (state: RootState) => state.programOverview.userRole;