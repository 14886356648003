interface IAuth {
  identityPoolId: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string
  mandatorySignIn: boolean;
  oauth: {
    domain: string;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };

}

enum Domain {
  alpha = "alpha",
  test = "test",
  dev = "dev",
  beta = "beta",
  prod = "prod",
}

type EnvType = Domain;
const AUTH_CONFIGS: Record<EnvType, IAuth> = {
  test: {
    identityPoolId: 'us-east-1:4048f474-4a54-43c1-9dfc-a680de1b3e44',
    region: 'us-east-1',
    userPoolId: 'us-east-1_UQj6k3ZeW',
    userPoolWebClientId: '2oiua22dlk8f5toe6pc5pt4eb4',
    mandatorySignIn: true,
    oauth: {
        domain: 'labfindgservice-alpha.auth.us-east-1.amazoncognito.com',
        scope: [
            "openid",
            "email",
            "aws.cognito.signin.user.admin",
            "profile"
        ],
        redirectSignIn: 'http://localhost:4321',
        redirectSignOut: 'http://localhost:4321',
        responseType: 'code'
    }
  },
  beta: {
    identityPoolId: 'us-east-1:cbb28812-3be0-4eef-b278-5d2896997274',
    region: 'us-east-1',
    userPoolId: 'us-east-1_43WYGVBIa',
    userPoolWebClientId: '4k81ab1ljigg1m2u95issebsf5',
    mandatorySignIn: true,
    oauth: {
        domain: 'labfindgservice-beta.auth.us-east-1.amazoncognito.com',
        scope: [
            "openid",
            "email",
            "aws.cognito.signin.user.admin",
            "profile"
        ],
        redirectSignIn: 'https://beta.verity-dg.device.finance.amazon.dev',
        redirectSignOut: 'https://beta.verity-dg.device.finance.amazon.dev',
        responseType: 'code'
    }
  },
  prod: {
    identityPoolId: 'us-east-1:9e67af00-4e4e-4a6b-9417-9a89c27099b7',
    region: 'us-east-1',
    userPoolId: 'us-east-1_EwbehXAHp',
    userPoolWebClientId: '1hks3qoqe92tc89n7jrkggmdk4',
    mandatorySignIn: true,
    oauth: {
        domain: 'labfindgservice-prod.auth.us-east-1.amazoncognito.com',
        scope: [
            "openid",
            "email",
            "aws.cognito.signin.user.admin",
            "profile"
        ],
        redirectSignIn: 'https://verity-dg.device.finance.amazon.dev',
        redirectSignOut: 'https://verity-dg.device.finance.amazon.dev',
        responseType: 'code'
    }
  },
  alpha: {
    identityPoolId: 'us-east-1:4048f474-4a54-43c1-9dfc-a680de1b3e44',
    region: 'us-east-1',
    userPoolId: 'us-east-1_UQj6k3ZeW',
    userPoolWebClientId: '2oiua22dlk8f5toe6pc5pt4eb4',
    mandatorySignIn: true,
    oauth: {
        domain: 'labfindgservice-alpha.auth.us-east-1.amazoncognito.com',
        scope: [
            "openid",
            "email",
            "aws.cognito.signin.user.admin",
            "profile"
        ],
        redirectSignIn: 'https://alpha.verity-dg.device.finance.amazon.dev',
        redirectSignOut: 'https://alpha.verity-dg.device.finance.amazon.dev',
        responseType: 'code'
    }
  },
  dev: {
    identityPoolId: 'us-east-1:4048f474-4a54-43c1-9dfc-a680de1b3e44',
    region: 'us-east-1',
    userPoolId: 'us-east-1_UQj6k3ZeW',
    userPoolWebClientId: '2oiua22dlk8f5toe6pc5pt4eb4',
    mandatorySignIn: true,
    oauth: {
        domain: 'labfindgservice-alpha.auth.us-east-1.amazoncognito.com',
        scope: [
            "openid",
            "email",
            "aws.cognito.signin.user.admin",
            "profile"
        ],
        redirectSignIn: 'http://localhost:4321',
        redirectSignOut: 'http://localhost:4321',
        responseType: 'code'
    }
  }
};
export const getDomain = () => {
  const stage = 'beta' as Domain;
  console.log(stage);
  return Domain[stage];
};
export const getAuthConfig = (): IAuth => AUTH_CONFIGS[getDomain()];