import * as React from "react";
import { Button, Container, Header, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/components/redux/hooks";
import { resetProductHierarchy, selectAllProductLines } from "../appLayout/appLayoutSlice";
import { INewProgram } from "../../interfaces/interfaces";
import { useCreateRPNMutation } from "../../services/api";
import { ALERT_MESSAGES, METRICS_APP_NAME, METRICS_PAGE_NAMES, MUTATION_METHODS, SCREEN_PATHS, STATUS_CODES, URL_PARAMS, USER_POLICY_SEPERATOR, USER_ROLES, VERITY_PL_LABEL } from "../../constants/constants";
import ScreenUtils from "../../utils/screenUtils";
import { CREATE_PROGRAM } from "../../constants/fieldData";
import { IDynamicFieldProps } from "src/components/common/DynamicForm/Interfaces";
import DynamicForm from "src/components/common/DynamicForm/DynamicForm";
import AlertMessageContext from "src/components/common/Contexts/alertMessageContext";
import { IApiUpdateResponse } from "src/components/Interfaces/interface";
import { useNavigate } from "react-router-dom";
import { selectUserPolicy } from "../userPolicy/userPolicySlice";
import { logUserAction } from "src/components/KatalMetrics/metricsHelper";
import { ACTIONS } from "src/components/KatalMetrics/metricsConstants";
import { Box } from "@amzn/awsui-components-react";

const initialState = {
    rpn: '',
    product_line: null,
    is_name_approved: "No",
    is_brd_approved: "No",
    is_prfaq_approved: "No",
    comment: ''
};

export const CreateNewProgram = () => {
    const dispatch = useAppDispatch();
    const [createProgram, { isLoading, data: mutationData, isError: mutationError }] = useCreateRPNMutation();
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const allProductLines = useAppSelector(selectAllProductLines);
    const userPolicy = useAppSelector(selectUserPolicy);
    const navigate = useNavigate();
    const [formInput, setFormInput] = useState<INewProgram>(initialState);
    const { generateOptionsFromIdLabel, validateProgramName } = ScreenUtils;
    const [plOptions, setPlOptions] = useState<Record<string, string>>({});

    useEffect(() => {
        const plOpt : Record<string, string> = {};
        if(userPolicy[USER_ROLES.plAdmin]) {
            Object.keys(userPolicy[USER_ROLES.plAdmin]).forEach(plKey => {
                const plId = plKey.split(USER_POLICY_SEPERATOR)[1] ?? '';
                if(allProductLines[plId]) plOpt[plId] = allProductLines[plId];
            });
        }
        setPlOptions(plOpt);
    }, [userPolicy, allProductLines]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) setError?.(ALERT_MESSAGES.programFailure);
            if (mutationData.data && mutationData.data[MUTATION_METHODS.createRPN]) {
                const { statusCode, error, updatedCodes } : IApiUpdateResponse = mutationData.data[MUTATION_METHODS.createRPN];
                if (statusCode === STATUS_CODES.success) {
                    if(updatedCodes?.length) {
                        const programId = updatedCodes[0].toString().split(',')[0].split('=')[1];
                        dispatch(resetProductHierarchy());
                        setSuccess?.(ALERT_MESSAGES.programSuccess);
                        const href = `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.programSetup}?${URL_PARAMS.productLine}=${formInput.product_line.value}-${formInput.product_line.label}&${URL_PARAMS.rpn}=${programId}-${formInput.rpn?.toUpperCase()}`;
                        navigate(href.substring(1), { replace: true });
                    }
                } else {
                    (statusCode === STATUS_CODES.handledException && error) ? setError?.(error) : setError?.(ALERT_MESSAGES.programFailure);
                }
            }
        }
    }, [mutationData, mutationError]);

    const setFieldValue = (value: any, field: string) => {
        setFormInput(values => ({
            ...values,
            [field]: field === "rpn" ? value.toString().toUpperCase() : value
        }));
    };

    const submit = async () => {
        const payload = {
            rpn: formInput.rpn,
            verity_product_line_id: parseInt(formInput.product_line!.value),
            is_name_approved: formInput.is_name_approved === 'No' ? false : true,
            is_brd_approved: formInput.is_brd_approved === 'No' ? false : true,
            is_prfaq_approved: formInput.is_prfaq_approved === "No" ? false : true,
            prfaq_link: formInput.prfaq_link,
            is_on_roadmap: true,
            program_status: 'Active',
            comment: formInput.comment,
            user_id: ScreenUtils.getUserId() ?? ''
        };
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        console.log(graphQL_payload);// console added for beta testing
        logUserAction(METRICS_APP_NAME, METRICS_PAGE_NAMES.createProgram, ACTIONS.CREATE_PROGRAM, {ProductLine: formInput.product_line!.label, RPN: formInput.rpn!});
        await createProgram(graphQL_payload).unwrap();
    };

    const loadForm = () => {
        const formInputMap = new Map<number, IDynamicFieldProps[]>();
        const plOpts = plOptions ? generateOptionsFromIdLabel(plOptions) : [];
        Object.entries(CREATE_PROGRAM).forEach(([field, fMetadata]) => {
            const allOptions = field === VERITY_PL_LABEL ? plOpts : fMetadata.options;
            const inputObj: IDynamicFieldProps = {
                "name": field,
                "label": fMetadata.label,
                "type": fMetadata.type,
                "value": formInput[field as keyof typeof formInput],
                "readOnly": fMetadata.readonly,
                "full_width": fMetadata.fullWidth ?? false,
                "placeholder": fMetadata.placeholder ?? '',
                "options": allOptions,
                "action": setFieldValue,
                "filteringType": fMetadata.filteringType ?? 'none',
                "validation": field === 'rpn' ? validateProgramName : undefined
            };
            formInputMap.has(fMetadata.row!) ? formInputMap.get(fMetadata.row!)?.push(inputObj) : formInputMap.set(fMetadata.row!, [inputObj]);
        });

        return (
            <DynamicForm formInput={Array.from(formInputMap.values())} />
        );
    };

    if (isLoading) return <div className='loadspinner mg-top-md'><Spinner size="large" /></div>;

    return (

        <Container
            header={
                <Header variant="h2">
                    Create program
                </Header>
            }
            footer={
                <Box> Note: Program will be created with Active status</Box>
              }
        >
            <SpaceBetween size="xxl">
                {loadForm()}
                <Button className="bg-primary" onClick={submit}
                    ariaLabel="create-new-program"
                    disabled={
                        formInput.rpn && 
                        formInput.product_line && 
                        !validateProgramName(formInput.rpn) && 
                        ((formInput.is_prfaq_approved === "Yes" && formInput.prfaq_link) || formInput.is_prfaq_approved === "No")
                        ? false : true}
                    variant="primary">
                    Create new program</Button>
            </SpaceBetween>
        </Container>

    );
};