export default class Queries {

    public static readonly getUserValidation = (userId: string) => {
        return {
            "query": `query {
                ca_user_validation(emp_login: "${userId}") {
                    statusCode
                    error
                    body {
                      exact_match {
                        business_title
                        employee_login
                        employee_name
                        department_name
                      }
                      fuzzy_match {
                        business_title
                        employee_login
                        employee_name
                        department_name
                      }
                    }
                }
            }`
        };
    }

    public static readonly getUserAccess = () => {
        return `query ($user_name: String) {
            get_user_access(user_name: $user_name) {
                body {
                    resource {
                      name
                      resources {
                        name
                      }
                    }
                    roles {
                      name
                    }
                  }
                error
                statusCode
              }
        }`;
    }

    public static readonly getPRMScenarios = () => {
        return `query {
            get_prm_scenarios(exclude_automatic_scenarios: true) {
                body {
                    scenario_id
                    scenario_name
                  }
                  error
                  statusCode
              }
        }`;
    }


    public static readonly getProgramOverview = (PK: string) => {
        return {
            "query": `query {
                get_prog_overview(PK: "${PK}")  {
                    body {
                        PK
                        SK
                        ItemValues
                        ItemValuesSubmitted
                        ItemValuesWIP
                        IsInheritedByFPN
                        IsPartOfFPN
                        SetAsTBD
                        Stage
                        Version
                    }
                    error
                    statusCode
                }
          }`
        };
    }

    public static readonly getRPNDetails = (programId: number) => {
        return {
            "query": `query {
                get_rpn(verity_rpn_id: ${programId}) {
                    body {
                        is_brd_approved
                        is_name_approved
                        is_prfaq_approved
                        prfaq_link
                        rpn_alias
                        asin_count
                        is_on_roadmap
                        product_code
                        ppls_id
                        rpn
                        program_status
                        created_ts
                    }
                    error
                    statusCode
                }
          }`
        };
    }

    public static readonly getFPNDetails = (programId: number) => {
        return {
            "query": `query {
                get_fpn(verity_fpn_id: ${programId}) {
                    body {
                        asin_count
                    }
                    error
                    statusCode
                }
          }`
        };
    }

    public static readonly getFPNsByRPN = (programId: number) => {
        return {
            "query": `query {
                get_fpn(verity_rpn_id: ${programId}) {
                    body {
                        is_current
                        fpn
                        verity_fpn_id
                        is_fpn_key_active
                        asin_count
                    }
                    error
                    statusCode
                }
          }`
        };
    }

    public static readonly getAttributesLists = () => {
        return {
            "query": `query {
                get_attribute_list {
                    body {
                      attribute_type_id
                      id
                      name
                    }
                    error
                    statusCode
                }
          }`
        };
    }

    public static readonly getAttributesTypes = () => {
        return {
            "query": `query {
                get_attribute_type {
                    body {
                      attribute_type
                      is_standard
                      id
                    }
                    error
                    statusCode
                  }
          }`
        };
    }

    public static readonly getPLAttributesTypes = (PL: number) => {
        return {
            "query": `query {
                get_attribute_pl_type(is_pl_attr: true, is_deprecated: false, product_line_id: ${PL}) {
                    body {
                        attribute_type
                        attribute_type_id
                        pl_attr_order
                        is_inherited_by_fpn
                    }
                    error
                    statusCode
                  }
          }`
        };
    }

    public static readonly getCostCenterCodes = () => {
        return {
            "query": `query {
                get_cc {
                    body {
                        cost_center_code
                        cost_center_name
                    }
                    error
                    statusCode
                  }
          }`
        };
    }

    public static readonly getPRMFormResources = () => {
        return `query ($for_chart: Boolean) {
            get_prm_attributes(for_chart: $for_chart) {
                body {
                    attribute_id
                    attribute_label
                    attribute_type
                    attribute_value
                  }
                  error
                  statusCode
              }
        }`;
    }

    public static readonly getPRMGanttChartData = () => {
        return `query ($product_line_id: Int!, $attribute_ids: [Int]!, $scenario_id: Int!, $country: String) {
            get_prm_gantt_chart_data(product_line_id: $product_line_id, chart_filters: {attribute_ids: $attribute_ids, country: $country, scenario_id: $scenario_id}) {
                body {
                  attributes {
                    attribute_type
                    attribute_id
                    attribute_label
                    attribute_value
                  }
                  end_date
                  program_alias
                  program_id
                  start_date
                }
                error
                statusCode
              }
              
        }`;
    }

    public static readonly getUpdateQuery = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
                ${api_name}(input: ${payload}) {
                    body
                    error
                    statusCode
                }
            }`
        };
    }
    public static readonly getUpdateQueryWithCodes = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
                ${api_name}(input: ${payload}) {
                    body
                    error
                    statusCode
                    updatedCodes
                    erroredCode
                }
            }`
        };
    }
}
