import React from 'react';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { useAppSelector } from '../../../redux/hooks';
import { selectVerityData } from '../programOverview/programOverviewSlice';

export const StatusInformation = () => {
    const {isNameApproved, isBRDApproved, isOnRoadmap, isPRFAQApproved, prfaqLink, programStatus, createdDate} = useAppSelector(selectVerityData);
    const getValue = (value: null | boolean) => value !== null ? value === true ? 'Yes' : 'No' : '';
    return (
        <>
            <Container
                header={
                    <Header variant="h2">
                        Status
                    </Header>
                }
            >   <SpaceBetween className='mg-top-md' size="xl">
                    <ColumnLayout columns={4} variant="text-grid">
                        <div>
                            <div>
                                <Box variant="awsui-key-label">Program status</Box>
                                <div>{programStatus}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Box variant="awsui-key-label">Program creation date</Box>
                                <div>{createdDate}</div>
                            </div>
                        </div>
                    </ColumnLayout>
                    <ColumnLayout columns={4} variant="text-grid">
                        <div>
                            <div>
                                <Box variant="awsui-key-label">Program name approved</Box>
                                <div>{getValue(isNameApproved)}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Box variant="awsui-key-label">BRD approved</Box>
                                <div>{getValue(isBRDApproved)}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Box variant="awsui-key-label">Roadmap program</Box>
                                <div>{getValue(isOnRoadmap)}</div>
                            </div>
                        </div>
                    </ColumnLayout>
                    <ColumnLayout columns={4} variant="text-grid">
                        <div>
                            <div>
                                <Box variant="awsui-key-label">PRFAQ approved</Box>
                                <div>{getValue(isPRFAQApproved)}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Box variant="awsui-key-label">PRFAQ Link</Box>
                                <div>{prfaqLink}</div>
                            </div>
                        </div>
                    </ColumnLayout>
                </SpaceBetween>
            </Container>
        </>
    );
};
