import { InputProps } from "@cloudscape-design/components";

export interface IFieldMetaData {
    label: string
    type: any
    inputType?: InputProps.Type
    row?: number
    placeholder?: string
    readonly?: boolean
    options?: any
    actions?: any
    filteringType?: "none" | "manual" | "auto"
    fullWidth?: boolean
    visibility?: boolean
    validation?: any
    onBlur?: any
}

export const FIELD_NAMES = {
    createRpnRpn: 'rpn',
    specsDispEnabled : 'Display enabled',
    specsDispAttr: 'Display attributes',
    specsMicEnabled : 'Mic enabled',
    specsMicRange : 'Mic field range',
    specsProjCoa: 'Project COA',
    financialBsnJustification: 'Business justification',
    financialRmrsePolicy : 'Remorse policy',
    programPredecessor: 'Program predeccessor',
    programFollowOn: 'Program follow-on',
    programDesc: 'Program description',
    comments: 'Comments',
    majorCallOuts: 'Major call outs',
    ContactAddInfo: 'Contacts for Additional Info',
    fsdCountry: 'FSD Countries',
    fsdate: 'FSD Date'
};

export const CREATE_PROGRAM : Record<string, IFieldMetaData> = {
    "rpn": {
        label: "Program name",
        type: "input",
        placeholder: "Enter program name",
        row: 1
    },
    "product_line": {
        label: "Product line",
        type: "select",
        placeholder: "Select a product line",
        row: 2,
        filteringType: 'auto'
    },
    "is_name_approved": {
        label: "Is name approved",
        type: "radio",
        options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" }
        ],
        row: 3
    },
    "is_brd_approved": {
        label: "Is BRD approved",
        type: "radio",
        options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" }
        ],
        row: 3
    },
    "is_prfaq_approved": {
        label: "Is PRFAQ approved",
        type: "radio",
        options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" }
        ],
        row: 3
    },
    "prfaq_link": {
        label: "PRFAQ link",
        type: "input",
        placeholder: "Required if program is PRFAQ approved",
        fullWidth: true,
        row: 4
    },
    "comment": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

export const PROGRAM_OVERVIEW : Record<string, IFieldMetaData> = {
    "Confidential": {
        label: "Confidential",
        type: "toggle",
        row: 1
    },
    [FIELD_NAMES.programPredecessor]: {
        label: "Program predecessor",
        type: "select",
        row: 2,
        filteringType: 'auto'
    },
    [FIELD_NAMES.majorCallOuts]: {
        label: "Major call outs",
        type: "textArea",
        placeholder: "Enter major call outs",
        row: 3
    },
    [FIELD_NAMES.programDesc]: {
        label: "Program description",
        type: "textArea",
        placeholder: "Enter description",
        row: 3
    },
    [FIELD_NAMES.comments]: {
        label: "Program comments",
        type: "textArea",
        placeholder: "Enter comments",
        row: 4
    },
    "Compatible devices": {
        label: "Compatible devices",
        type: "textArea",
        placeholder: "Only applicable for Accessories. Enter compatible devices separated by ';'",
        row: 4
    }
};

export const CONTACT_INFO : Record<string, IFieldMetaData> = {
    "Contacts for Additional Info": {
        label: "Contacts for Additional Info",
        type: "userSearchMultiselect",
        row: 1
    },
    "PM Owner(s)": {
        label: "PM Owner(s)",
        type: "userSearchMultiselect",
        row: 1
    },
    "Accessory Team POC": {
        label: "Accessory Team POC",
        type: "userSearchMultiselect",
        row: 2
    },
    "Others": {
        label: "Others",
        type: "userSearchMultiselect",
        row: 2
    }
};

export const OTHER_KEY_DATES : string[] = [
    "Last PDP Gate / Month", 
    "Last Freshlot Sale Month / Qtr", 
    "End of Software Support", 
    "End of Security Upgrades", 
    "Product Announce (PA) Date", 
    "End of Manufacturing", 
    "End of life"
];

export const FINANCIAL_INFO : Record<string, IFieldMetaData> = {
    "US MSRP(USD)": {
        label: "US MSRP(USD)",
        type: "input",
        inputType: "number",
        placeholder:"Enter dollar amount",
        row: 1
    },
    "WW MSRP(USD)": {
        label: "WW MSRP(USD)",
        type: "input",
        inputType: "number",
        placeholder:"Enter dollar amount",
        row: 1
    },
    "WW ASP(USD)": {
        label: "WW ASP(USD)",
        type: "input",
        inputType: "number",
        placeholder:"Enter dollar amount",
        row: 2
    },
    "WW net promo %": {
        label: "WW net promo %",
        type: "input",
        inputType: "number",
        placeholder:"Enter net promo",
        row: 2
    },
    "Warranty offering": {
        label: "Warranty offering",
        type: "select",
        row: 3,
        options: [{label:"3 mo", value:"3 mo"}, {label:"12 mo", value:"12 mo"}, {label:"US/JP:3 mo / ROW:12 mo", value:"US/JP:3 mo / ROW:12 mo"}, {label:"12mo / KIDS:24mo", value:"12mo / KIDS:24mo"}]
    },
    [FIELD_NAMES.financialRmrsePolicy]: {
        label: "Remorse policy",
        type: "input",
        row: 4,
        placeholder: "Enter remorse policy (eg: 30)"
    },
    [FIELD_NAMES.financialBsnJustification]: {
        label: "Business justification",
        type: "textArea",
        row: 4,
        placeholder: "Enter business justification for remorse policy",
        visibility: false
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

export const MANUFACTORING_INFO : Record<string, IFieldMetaData> = {
    "Partiness": {
        label: "Partiness",
        type: "select",
        row: 1,
        options: [{label:"1P", value:"1P"}, {label:"2P", value:"2P"}, {label:"3P", value:"3P"}]
    },
    "Manufacturing: Engagement": {
        label: "Manufacturing: Engagement",
        type: "select",
        options: [{label:"Low", value:"Low"}, {label:"Med", value:"Med"}, {label:"High", value:"High"}],
        row: 1
    },
    "Number of manufacturers": {
        label: "Number of manufacturers",
        type: "input", 
        row: 2,
        inputType: "number"
    },
    "Manufacturer: company": {
        label: "Manufacturer: company",
        type: "input",
        row: 2
    },
    "Product life (in months)": {
        label: "Product life (in months)",
        type: "input", 
        row: 3,
        inputType: "number"
    },
    "Cost center code": {
        label: "Cost center code",
        type: "select",
        row: 3
    },
    "Project HW Id": {
        label: "Project HW Id",
        type: "input",
        row: 4
    },
    "HW program size": {
        label: "HW program size",
        type: "select",
        options: [{label:"XS", value:"XS"}, {label:"S", value:"S"}, {label:"M", value:"M"}, {label:"L", value:"L"}, {label:"XL", value:"XL"}],
        row: 4
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

export const MARKET_INFO : Record<string, IFieldMetaData> = {
    "Global Life Volume Est": {
        label: "Global Life Volume Est",
        type: "input",
        inputType: "number",
        row: 1
    },
    "Primary or Expansion Launch": {
        label: "Primary or Expansion Launch",
        type: "select",
        options: [{label:"Primary", value:"Primary"}, {label:"Expansion", value:"Expansion"}],
        row: 1
    },
    "Significance Vol at Resellers": {
        label: "Significance Vol at Resellers",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}, {label:"TBD", value:"TBD"}],
        row: 2
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 3
    }
};

export const SPECS : Record<string, IFieldMetaData> = {
    "Alexa enabled": {
        label: "Alexa enabled",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
        row: 1
    },
    "Camera enabled": {
        label: "Camera enabled",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
        row: 1
    },
    "New SoC": {
        label: "New SoC",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
        row: 2
    },
    [FIELD_NAMES.specsProjCoa]: {
        label: "Project COA",
        type: "input",
        placeholder: "Enter Project COA",
        row: 2
    },
    [FIELD_NAMES.specsDispEnabled]: {
        label: "Display enabled",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
        row: 3
    },
    [FIELD_NAMES.specsDispAttr]: {
        label: "Display attributes",
        type: "select",
        visibility: false,
        options: [{label:"S", value:"S"}, {label:"M", value: "M"}, {label:"L", value: "L"}, {label:"N/A", value: "N/A"}, {label:"TBD", value: "TBD"}],
        row: 3
    },
    [FIELD_NAMES.specsMicEnabled]: {
        label: "Mic enabled",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
        row: 4
    },
    [FIELD_NAMES.specsMicRange]: {
        label: "Mic field range",
        type: "select",
        visibility: false,
        options: [{label:"NEAR", value:"NEAR"}, {label:"MID", value: "MID"},  {label:"FAR", value: "FAR"}],
        row: 4
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

